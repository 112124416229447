import { useEffect, useState  } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Paper, Tooltip } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiRef, gridFilteredSortedRowEntriesSelector, renderBooleanCell } from '@mui/x-data-grid';
import LaunchIcon from '@mui/icons-material/Launch';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import '../css/styles.css';
import ParticipantForm from "./participantForm";
import toast from "react-hot-toast";
import API from "../../Visa/API";
import { toProperCase, isBirthDayInRange, calculateAge } from "../../utils"

// Function to format dates to 'dd/mm/yyyy'
const formatDate = (date) => {
  if (!date) return 'N/A';
  const d = new Date(date);
  if (isNaN(d)) return 'Invalid Date';
  return `${
    ('0' + d.getDate()).slice(-2)
  }/${
    ('0' + (d.getMonth() + 1)).slice(-2)
  }/${
    d.getFullYear()
  }`;
};

const createAndOpenHubspot = async (participant) => {
  toast.loading("Finding Hubspot Contact");
  try {
    const response = await API.post('/internal/createHubspotContact', participant);
    toast.dismiss();
    toast.success(response.data.message);
    if (response.data.hubspot_id){
      window.open(`https://app.hubspot.com/contacts/20340520/record/0-1/${response.data.hubspot_id}`)
    }
  } catch (error) {
    console.error(error)
    toast.dismiss();
    toast.error(error.message);
  }
}

const sendWhatsappReminder = (firstName, whatsappNumber, expiringDocument, expiryDate) => {
  window.open(`https://api.whatsapp.com/send?phone=${whatsappNumber}&text=Hello%20${firstName}%2C%0AJust%20wanted%20to%20follow%20up%20with%20you%20regarding%20your%20${expiringDocument}%20expiry%20as%20the%20document%20shared%20with%20us%20expires%20on%20${expiryDate}.%0APlease%20update%20if%20you%27ve%20received%20your%20updated%20Document.%20Thank%20you.%0A%0AKind%20Regards%2C%0AMarcopolo%20Holidays`)
};

const GroupTable = ({ primaryDestination, fetchGroup, participantData, GroupTourId, isGroupAdmin, tourDuration, visaApplicationDate }) => {
  const apiRef = useGridApiRef();
  const navigate = useNavigate();

  const [participantOpen, setParticipantOpen] = useState(false);
  const [individualParticipantData, setIndividualParticipantData] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();

  const openParticipantForm = (partcipantData) => {
    setIndividualParticipantData(partcipantData);
    setParticipantOpen(true);
  }

  useEffect(() => {
    const participantName = searchParams.get('participant');
    if (participantName && participantName.length > 0) {
      const participant = participantData.find(p => p.fullName === participantName);
      if (participant) {
        openParticipantForm(participant);
      }
    }
  }, [searchParams]);

  const fullNameClassNames = (params) => {
    const className = (
      params.row.violations.isUAEVisaViolated || 
      params.row.violations.isPassportViolated ||
      (Object.values(params.row.isVerified?.passportData?params.row.isVerified?.passportData:[]).filter(value => value === true).length<9) ||
      (Object.values(params.row.isVerified?.uaeResidenceVisaData?params.row.isVerified?.uaeResidenceVisaData:[]).filter(value => value === true).length<4)
    ) ? 'expiring word-break-cell' : 'word-break-cell';
    return className;
  }

  const passportExpiryClassNames = (params) => {
    if (Object.values(params.row.isVerified?.passportData?params.row.isVerified?.passportData:[]).filter(value => value === true).length<9){
      return 'warning';
    } else if (params.row.violations.isPassportViolated){
      return 'expiring'
    } else {
      return '';
    }
  };

  const passportExpiryOnClickEvent = (e, params) => {
    if (Object.values(params.row.isVerified?.passportData?params.row.isVerified?.passportData:[]).filter(value => value === true).length<9){
      return navigate(`/group-verification/${GroupTourId}?participant=${params.row.fullName}&documentType=Passport`);
    } else if (params.row.violations.isPassportViolated){
      return sendWhatsappReminder(params.row.firstName,params.row.whatsappNumber,params?.row?.travelDocumentType,formatDate(params.row.passportExpiryDate))
    } 
  }  

  const uaeVisaExpiryClassNames = (params) => {
    if (Object.values(params.row.isVerified?.uaeResidenceVisaData?params.row.isVerified?.uaeResidenceVisaData:[]).filter(value => value === true).length<4){
      return 'warning';
    } else if (params.row.violations.isUAEVisaViolated){
      return 'expiring'
    } else {
      return '';
    }
  };

  const uaeVisaExpiryOnClickEvent = (e, params) => {
    if (Object.values(params.row.isVerified?.uaeResidenceVisaData?params.row.isVerified?.uaeResidenceVisaData:[]).filter(value => value === true).length<4){
      return navigate(`/group-verification/${GroupTourId}?participant=${params.row.fullName}&documentType=Passport`);
    } else if (params.row.violations.isUAEVisaViolated){
      return sendWhatsappReminder(params.row.firstName,params.row.whatsappNumber,"UAE Residence Visa",formatDate(params.row.uaeResidenceVisa?.dateOfExpiry))
    } 
  };  

  const columns = [
    {field: 'fullName',
      headerName: 'Full Name',
      width: 350, 
      valueGetter: (params) => !params.row.cancelled.status?(params.row.fullName):'',
      cellClassName: (params) => (fullNameClassNames(params)),
      renderCell: (params) => (
        <div
        onClick={params.row.cancelled?.status ? undefined : () => { setSearchParams({ participant: params.row.fullName }); }}
        style={{  cursor:  params.row.cancelled?.status ? 'default' : 'pointer', alignItems:'center', display: 'flex' }}
        >
          {toProperCase(params.row.fullName)}
          {calculateAge(params.row.dateOfBirth, params.row?.visaApplicationDate?params.row.visaApplicationDate:visaApplicationDate)<18 && (
            <Tooltip title="Minor At the Time of Visa Application">
              <EscalatorWarningIcon className="mx-2" />
            </Tooltip>
          )}
          {isBirthDayInRange(tourDuration.tourStartDate, tourDuration.tourEndDate, params.row.dateOfBirth) && (
            <Tooltip title="Birthday Falls During Tour Duration">
              <img src={`${process.env.PUBLIC_URL}/cake.svg`} alt="Birthday Wish" style={{ width: '20px', height: '20px' }} className="mx-2" />
            </Tooltip>
          )}
          
          {params.row.preferences?.privateRoomRequest?.termsAccepted===true
            ?
              <Tooltip title="Requested For Single Room">
                <img src={`${process.env.PUBLIC_URL}/single-bed.svg`} alt="Single Room" style={{ width: '20px', height: '20px' }} className="mx-2" />
              </Tooltip>
            :''}
          </div>
      )       
    },
    {field: 'gender',
      headerName: 'Gender',
      width: 100, 
      cellClassName: 'word-break-cell',
      valueGetter: (params) => !params.row.cancelled.status?(params.row.gender):'',
      renderCell: (params) => (
        <div>
          {params.row.gender.slice(0,1)}
        </div>
      )       
    },    
    {field: 'dateOfBirth',
      headerName: 'Date Of Birth',
      width: 125,
      valueGetter: (params) => !params.row.cancelled.status?new Date(params.row.dateOfBirth):'',
      renderCell: (params) => {
        
        return (
          <div>
            <Tooltip title={`${calculateAge(params.row.dateOfBirth, new Date())} years old`}>
              {params.row.dateOfBirth ? formatDate(params.row.dateOfBirth) : 'N/A'}
            </Tooltip>
          </div>
        )
      }
    },
    {field: 'nationality',
      headerName: 'Nationality',
      width: 250, 
      cellClassName: 'word-break-cell',
      valueGetter: (params) => !params.row.cancelled.status?(params.row.nationality):'',
      renderCell: (params) => (
        <div>
          {params.row.nationality}
        </div>
      )      
    },
    {field: 'passportExpiryDate',
      headerName: 'Passport Expiry',
      valueGetter: (params) => !params.row.cancelled.status?new Date(params.row.passportExpiryDate):'',
      cellClassName: (params) => (passportExpiryClassNames(params)),
      renderCell: (params) => (
        <div
          onClick={params.row.cancelled?.status ? undefined : (e) => passportExpiryOnClickEvent(e, params)}
          style={{  cursor:  params.row.cancelled?.status ? 'default' : 'pointer', alignItems:'center', display: 'flex' }}
        >
          {params.row.passportExpiryDate
            ? formatDate(params.row.passportExpiryDate)
            : 'N/A'}
        </div>
      )      
    },
    {field: 'uaeVisaExpiryDate',
      headerName: 'UAE Visa Expiry',
      width: 125, 
      valueGetter: (params) => !params.row.cancelled.status?new Date(params.row.uaeResidenceVisa?.dateOfExpiry):'',
      cellClassName: (params) => (uaeVisaExpiryClassNames(params)),
      renderCell: (params) => (
        <div
          onClick={params.row.cancelled?.status ? undefined : (e) => uaeVisaExpiryOnClickEvent(e, params)}
          style={{  cursor:  params.row.cancelled?.status ? 'default' : 'pointer', alignItems:'center', display: 'flex' }}
        >
          {params.row.uaeResidenceVisa?.dateOfExpiry
            ? formatDate(params.row.uaeResidenceVisa.dateOfExpiry)
            : 'N/A'}
        </div>
      )
    },
    {field: 'uaeVisaEmirateOfIssue',
      headerName: 'UAE Visa Issue Emirate',
      width: 125, 
      valueGetter: (params) => !params.row.cancelled.status?(params.row.uaeResidenceVisa?.emirateOfIssue
        ? params.row.uaeResidenceVisa.emirateOfIssue
        : 'N/A'):'',
      renderCell: (params) => (
        <div
          onClick={params.row.cancelled?.status ? undefined : (e) => uaeVisaExpiryOnClickEvent(e, params)}
          style={{  cursor:  params.row.cancelled?.status ? 'default' : 'pointer', alignItems:'center', display: 'flex' }}
        >
          {params.row.uaeResidenceVisa?.emirateOfIssue
            ? params.row.uaeResidenceVisa.emirateOfIssue
            : 'N/A'}
        </div>
      )
    },    
    {field: 'visaRequired',
      headerName: 'Visa Required?',
      width: 125, 
      valueGetter: (params) => !params.row.cancelled.status?(params.row.violations?.isVisaRequired ? 'Yes' : 'No'):'',
      renderCell: (params) => (
        <div>
          {params.row.violations?.isVisaRequired ? 'Yes' : 'No'}
        </div>
      )
    },
    {field: 'expiringPassport',
      headerName: 'Expiring Passport',
      width: 125, 
      valueGetter: (params) => !params.row.cancelled.status?params.row.violations?.isPassportViolated:'',
      cellClassName: (params) =>
        params.row.violations?.isPassportViolated ? 'expiring' : '',
      renderCell: (params) => {
        const isPassportViolated = params.row.violations?.isPassportViolated;
        return (
          <div
            onClick={
              isPassportViolated
                ? () => sendWhatsappReminder(
                    params.row.firstName,
                    params.row.whatsappNumber,
                    params?.row?.travelDocumentType,
                    formatDate(params.row.passportExpiryDate)
                  )
                : undefined // No click handler if it's not violated
            }
            style={{
              cursor: isPassportViolated ? 'pointer' : 'default' // Show pointer cursor only if clickable
            }}
          >
            {isPassportViolated ? 'Yes' : 'No'}
          </div>
        );
      }
    },    
    {field: 'expiringUaeVisa',
      headerName: 'Expiring UAE Visa',
      valueGetter: (params) => !params.row.cancelled.status?params.row.violations?.isUAEVisaViolated:'',
      cellClassName: (params) =>
        params.row.violations?.isUAEVisaViolated
          ? 'expiring'
          : '',
      renderCell: (params) => {
        const isUAEVisaViolated = params.row.violations?.isUAEVisaViolated;
        return (
          <div
            onClick={
              isUAEVisaViolated
                ? () => sendWhatsappReminder(
                    params.row.firstName,
                    params.row.whatsappNumber,
                    "UAE Residence Visa",
                    formatDate(params.row.uaeResidenceVisa?.dateOfExpiry)
                  )
                : undefined // No click handler if it's not violated
            }
            style={{
              cursor: isUAEVisaViolated ? 'pointer' : 'default' // Show pointer cursor only if clickable
            }}
          >
            {isUAEVisaViolated ? 'Yes' : 'No'}
          </div>
        );
      }
    },
    {field: 'actions',
      headerName: 'Actions',
      width: 230,
      renderCell: (params) => {
        const isPassportViolated = params.row.violations?.isPassportViolated;
        const isUAEVisaViolated = params.row.violations?.isUAEVisaViolated;
        return (<div>
          <img className="mx-1" src={params.row.cancelled.status?`${process.env.PUBLIC_URL}/hubspot_grey.svg`:`${process.env.PUBLIC_URL}/hubspot.svg`} alt="Passport Control" style={{ width: '20px', height: '20px', cursor: 'pointer' }} 
            onClick={() => (createAndOpenHubspot(params.row))}
          />  
          <WhatsAppIcon
            className="mx-1"
            style={{ cursor: 'pointer' }}
            onClick={()=>(window.open(`https://api.whatsapp.com/send?phone=${params.row.whatsappNumber}`))}
          />
          <EmailIcon
            className="mx-1"
            style={{ cursor: 'pointer' }}
            onClick={()=>(window.open(`mailto:${params.row?.personalEmail}?cc=${params.row?.secondaryEmail}&bcc=20340520@bcc.hubspot.com`))}
          />
          {!params.row.cancelled.status && (
            <>
            <LaunchIcon
              className="mx-1"
              style={{ cursor: 'pointer' }}
              onClick={() => {setSearchParams({ participant: params.row.fullName})}}
            />
            {isPassportViolated && (
              <img className="mx-1" src={`${process.env.PUBLIC_URL}/passport-control.svg`} alt="Passport Control" style={{ width: '20px', height: '20px', cursor: 'pointer' }} 
                onClick={
                  isPassportViolated
                    ? () => sendWhatsappReminder(
                        params.row.firstName,
                        params.row.whatsappNumber,
                        params?.row?.travelDocumentType,
                        formatDate(params.row.passportExpiryDate)
                      )
                    : undefined // No click handler if it's not violated
                }
              />
            )}
            {isUAEVisaViolated && (
              <img className="mx-1" src={`${process.env.PUBLIC_URL}/united-arab-emirates.svg`} alt="UAE Visa Control" style={{ width: '20px', height: '20px', cursor: 'pointer' }} 
                onClick={
                  isUAEVisaViolated
                    ? () => sendWhatsappReminder(
                        params.row.firstName,
                        params.row.whatsappNumber,
                        "UAE Residence Visa",
                        formatDate(params.row.uaeResidenceVisa?.dateOfExpiry)
                      )
                    : undefined // No click handler if it's not violated
                }
              />
            )}
            <Tooltip title={params.row.cancelled.remarks}>
              <PersonRemoveIcon className="mx-1" style={{color: '#DD2E18'}}/>
            </Tooltip>
            </>
          )}
          {params.row.cancelled.status && (
            <Tooltip title={params.row.cancelled.remarks}>
              <PersonAddIcon className="mx-1" />
            </Tooltip>
          )}
        </div>)
      },
    },
    {field: 'emirateOfResidence',
      headerName: 'Residence Emirate',
      flex: 1,
      valueGetter: (params) => !params.row.cancelled.status?params.row.emirateOfResidence:'',
      renderCell: (params) => {
        <div>
          {params.row.emirateOfResidence}
        </div>        
      }
    },
    {field: 'Other',
      headerName: 'Other',
      flex: 1,
      cellClassName: 'word-break-cell',
      valueGetter: (params) => !params.row.cancelled.status?params.row.preferences.others:'',
    },
    {field: 'PersonalEmail',
      headerName: 'Personal Email',
      width: 200, 
      valueGetter: (params) => !params.row.cancelled.status?params.row.personalEmail:'',
    },
    {field: 'SecondaryEmail',
      headerName: 'Secondary Email',
      width: 200, 
      valueGetter: (params) => !params.row.cancelled.status?params.row.secondaryEmail:'',
    },
    {field: 'profession',
      headerName: 'Profession',
      width: 200, 
      valueGetter: (params) => !params.row.cancelled.status?params.row.uaeResidenceVisa.profession:'',
    },
    {field: 'visaApplicationDate',
      headerName: `Appointment Date`,
      width: 200,
      valueGetter: (params) => !params.row.cancelled.status
      ?params.row.violations.isVisaRequired
        ?params?.row?.visaApplicationDate
          ?formatDate(params.row.visaApplicationDate)
          :'Pending'
        :'N/A'
      :'',
    }
  ];

  // Prepare rows for DataGrid
  const rows =
    participantData?.map((participant) => ({
      id: participant._id, // Use participant._id as the unique id
      ...participant,
    })) || [];

  // Handlers for actions (to be implemented)
  const handleView = (participant) => {
    // Implement view logic
    console.log('View participant:', participant);
  };

  const handleEdit = (participant) => {
    // Implement edit logic
    console.log('Edit participant:', participant);
  };

  // Optional styling for expiring documents
  const styles = {
    '& .expiring': {
      color: 'red',
      fontWeight: 'bold',
    },
  };

  const getFilteredSortedRows = () => {
    // Get the filtered and sorted row entries (participants) from the grid
    const participants = gridFilteredSortedRowEntriesSelector(apiRef);
  
    // Map through participants and get personalEmail and secondaryEmail
    const participantPersonalEmail = participants.map(p => p.model.personalEmail).filter(Boolean); // Ensure emails are valid
    const participantSecondaryEmail = participants.map(p => p.model.secondaryEmail).filter(Boolean); // Ensure emails are valid
  
    // Merge both arrays of emails
    const allEmails = [...participantPersonalEmail, ...participantSecondaryEmail];
  
    // Open the mail client with all emails, separated by semicolon
    if (allEmails.length > 0) {
      window.open(`mailto:?bcc=${allEmails.join(';')}`);
    } else {
      alert("No valid email addresses found.");
    }
  };

  return (
    <>
    <ParticipantForm
      primaryDestination={primaryDestination}
      fetchGroup={fetchGroup}
      open={participantOpen}
      handleClose={(e) => {setParticipantOpen(false); setSearchParams({})}}
      participantData={individualParticipantData}
      handleSubmit={(e)=>(console.log(e))}
      GroupTourId={GroupTourId}
    />
    <Paper
      sx={{
        width: '100%',
        overflow: 'hidden',
        border: 'none',
        boxShadow: 'none',
        ...styles, // Apply styles
      }}
    >
    <div className="container">
      <Tooltip title={"Email this filtered list via outlook with no personalisation"}>
        <Button onClick={getFilteredSortedRows}>
          Quick Email via Outlook
        </Button>  
      </Tooltip>
    </div>

    <DataGrid
      apiRef={apiRef}
      rowReordering
      rows={rows}
      columns={columns}
      getRowId={(row) => row._id}
      getRowClassName={(params) =>
        params.row.cancelled?.status ? 'strikethrough cancelledparticipant' : ''
      }
      disableMultipleColumnsSorting={false}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
        columns: {
          columnVisibilityModel: {
            // Add the list of columns that must be initially hidden here
            expiringUaeVisa: false, 
            expiringPassport: false, 
            PersonalEmail: false,
            SecondaryEmail: false,
            Other: false,
            profession: false,
            emirateOfResidence: false
          },
        },
      }}
      pageSizeOptions={[10,20,50,100]}
      rowsPerPageOptions={[10,20,50,100]}
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          printOptions: { disableToolbarButton: !isGroupAdmin },
          csvOptions: { disableToolbarButton: !isGroupAdmin },
          showQuickFilter: true,
        },
      }}
    />
    </Paper>
    </>
  );
};

export default GroupTable;
