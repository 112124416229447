
import { useEffect, useState } from "react";
import API from "../../Visa/API";
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Tooltip } from "@mui/material";

const Groups = () => {
    const navigate = useNavigate();

    const [groupTours, setGroupTours] = useState([]);
    const [isGroupAdmin, setIsGroupAdmin] = useState(false);

    useEffect(() => {
        const getStaffRole = async() => {
            const response = await API.get('/auth/getUserRole')
            console.log(response.data.staff_department)
            if (response.data.staff_department?.includes("groupsAdmin")){
                setIsGroupAdmin(true);
            }
        }        
        const fetchGroups = async () => {
            try {
                const response = await API.get('/groups/listTours');
                setGroupTours(response.data.data);
            } catch (e) {
                console.error(e);
            }
        };
        fetchGroups();
        getStaffRole();
    }, []);

    function formatDateToReadable(dateString) {
        const date = new Date(dateString);
    
        // Get the day of the month
        const day = date.getUTCDate();
        const daySuffix = getDaySuffix(day);
    
        // Get the month abbreviation
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getUTCMonth()]; // Months are zero-based in JavaScript
    
        // Get the last two digits of the year
        const year = date.getUTCFullYear().toString().slice(-2);
    
        return `${day}${daySuffix} ${month}, '${year}`;
    }
    
    // Helper function to determine the suffix for the day (st, nd, rd, th)
    function getDaySuffix(day) {
        if (day > 3 && day < 21) return "th"; // All days from 4 to 20 use "th"
        switch (day % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    }    

    return (
        <div style={{ padding: '20px' }}>
            <h1 className="d-flex justify-content-center align-items-center my-3">Group Tours&nbsp;&nbsp;&nbsp;
            {isGroupAdmin && (
            <Tooltip title="Create New Group Tour">
                <AddBoxIcon
                    onClick={()=>(navigate('/groups/group-settings'))} style={{cursor: 'pointer'}}
                />
            </Tooltip>
            )}

            </h1>
            {groupTours.length > 0 ? (
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                    {groupTours.map((tour, index) => (
                        <div
                            key={index}
                            className="col-md-3 container"
                            style={{
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                padding: '15px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                backgroundColor: '#fff',
                            }}
                        >
                            <div className="row justify-content-center">
                                <div className="col-10 justify-content-center">
                                    <h3 style={{ color: '#333', textAlign:'center' }}>{tour.tourName}</h3>
                                </div>
                                {isGroupAdmin && (
                                    <div className="col-1 justify-content-end">
                                        <Tooltip title="Group Settings">
                                            <SettingsIcon
                                                style={{'cursor': 'pointer'}}
                                                onClick={() => navigate(`/groups/group-settings/${tour._id}`)}
                                            />
                                        </Tooltip>
                                    </div>   
                                )}                             
                                <div className="col-12 justify-content-center">
                                    <p style={{ color: '#333', textAlign:'center' }}>{tour.PrimaryDestination} | {tour.tourDuration.numberOfDays} Days</p>
                                </div>
                                <div className="col-12 justify-content-center d-flex">
                                        <small style={{textAlign: 'center'}}><em>{formatDateToReadable(tour.tourDuration.tourStartDate)} to {formatDateToReadable(tour.tourDuration.tourEndDate)}</em></small>
                                </div>
                                <div className="col-12 justify-content-center d-flex">
                                        <small style={{textAlign: 'center'}}>{tour.participantsCount} Active Participants</small>
                                </div>                                
                                <div className="col-12 justify-content-center">
                                    {tour.tourDescription}
                                </div>
                                <div className="col-12 justify-content-center d-flex my-3">
                                    <button
                                        style={{
                                            padding: '10px 15px',
                                            backgroundColor: '#007bff',
                                            color: '#fff',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            marginTop: '10px'
                                        }}
                                        onClick={() => (navigate(`/groups/${tour._id}`))}
                                    >
                                        Open
                                    </button>
                                    &nbsp;&nbsp;&nbsp;
                                    <button
                                        style={{
                                            padding: '10px 15px',
                                            backgroundColor: (tour.participantsCount - tour.verifiedParticipantCount)>0?'#FFAB00':'ForestGreen',
                                            color: '#fff',
                                            border: 'none',
                                            borderRadius: '4px',
                                            cursor: 'pointer',
                                            marginTop: '10px'
                                        }}
                                        onClick={() => navigate(`/group-verification/${tour._id}`)}
                                    >
                                        Verify Details  {(tour.participantsCount - tour.verifiedParticipantCount)<=0?'':`(${(tour.participantsCount - tour.verifiedParticipantCount)})`}
                                    </button>
                                </div>                                
                            </div>

                        </div>
                    ))}
                </div>
            ) : (
                <p>No group tours available.</p>
            )}
        </div>
    );
};

export default Groups;
