import { useEffect, useState } from "react";
import API from "../../Visa/API";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ListGroup, Button, Navbar, Container, Form  } from "react-bootstrap";
import DocumentPreview from '../components/documentPreview';
import ParticipantDetails from '../components/participantDetails'
import toast from "react-hot-toast";

const GroupVerify = () => {
  const [documentTypes, setDocumentTypes] = useState(['Passport', 'Contact Info']);

  const navigate = useNavigate();
  const { GroupTourId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [tourName, setTourName] = useState('');
  const [primaryDestination, setPrimaryDestination] = useState('');
  const [selectedParticipant, setSelectedParticipant] = useState({});
  const [allParticipants, setAllParticipants] = useState([]);
  const [activeType, setActiveType] = useState('Passport');
  const [showEID, setShowEID] = useState(false);
  const [filter, setFilter] = useState(''); // State for filtering participants


  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const[base64Data, setBase64Data] = useState('');
  const[fileMimeType, setFileMimeType] = useState('');

  async function fetchBasicDetails() {
    toast.dismiss();
    toast.loading(`Loading ${searchParams.get('participant')?searchParams.get('participant')+"'s "+activeType:''}`)
    let response;
    setBase64Data('');
    setFileMimeType('');    
    try {
      response = await API.get(`groups/basicGroupDetails/${GroupTourId}?participant=${searchParams.get('participant')}`);
      setTourName(response.data.groupData.tourName);
      setPrimaryDestination(response.data.groupData.PrimaryDestination)
      console.log(response.data);
      setSelectedParticipant(response.data?.participantDetails);
      if (response.data?.participantDetails?.nationality.includes('UAE') || response.data?.participantDetails?.nationality.includes('United Arab Emirates')){
        setDocumentTypes(['Passport', 'Contact Info']);
      } else {
        setDocumentTypes(['Passport', 'UAE Visa', 'Contact Info']);
      }
      setAllParticipants(response.data.groupData.participants || []);
    } catch (e) {
      console.error(`Error: ${e}`);
    }
    let fileName, mimeType;
    if (!response.data.participantDetails){
      toast.dismiss();
      return;
    }
    
    switch(activeType) {
      case 'Passport':
        fileName = encodeURI(response.data.participantDetails?.passportURL) || '';
        mimeType = getMimeTypeFromFileName(response.data.participantDetails?.passportURL) || '';
        break;
      case 'UAE Visa':
        if (showEID===true){
          setShowEID(false)
          return;
        } else {
          fileName = encodeURI(response.data.participantDetails?.uaeResidenceVisa?.uaeResidenceVisaURL) || '';
          mimeType = getMimeTypeFromFileName(response.data.participantDetails?.uaeResidenceVisa?.uaeResidenceVisaURL) || '';          
        }
        break;
      default:
        fileName = '';
        mimeType = '';
        break;
    }
    try {
      let fileDate = await API.get(`internal/getFile?ParentFolder=Marcopolo%20Groups&fileName=${fileName}`);
      setBase64Data(fileDate.data.base64File);
      setFileMimeType(mimeType);
      toast.dismiss();
      toast.success("Successfully Loaded!");          
    } catch (error) {
      console.error(error);
      setBase64Data('');
      setFileMimeType('');
      toast.dismiss(); // Dismiss the loading toast
      toast.error("Something went wrong!");    
    }
  }

  async function switchUAEVisaAndEDI() {
    if (searchParams.get('documentType')!=='UAE Visa') {return;}
    
    setBase64Data('');
    setFileMimeType('');
    toast.dismiss();
    toast.loading(`Loading ${searchParams.get('participant')}'s ${showEID?'Emirates ID':'UAE Visa'}`);
    let fileName, mimeType; 
    if (showEID){
      fileName = encodeURI(selectedParticipant?.emiratesIdURL) || '';
      mimeType = getMimeTypeFromFileName(selectedParticipant?.emiratesIdURL) || '';
    } else {
      fileName = encodeURI(selectedParticipant?.uaeResidenceVisa?.uaeResidenceVisaURL) || '';
      mimeType = getMimeTypeFromFileName(selectedParticipant?.uaeResidenceVisa?.uaeResidenceVisaURL) || '';
    }
    try {
      let fileDate = await API.get(`internal/getFile?ParentFolder=Marcopolo%20Groups&fileName=${fileName}`);
      setBase64Data(fileDate.data.base64File);
      setFileMimeType(mimeType);      
      toast.dismiss(); // Dismiss the loading toast
      toast.success("Successfully Loaded!");          
    } catch (error) {
      console.error(error);
      setBase64Data('');
      setFileMimeType('');
      toast.dismiss(); // Dismiss the loading toast
      toast.error("Unable to Load File! Contact IT");    
    } 
  }

  useEffect(() => {
    fetchBasicDetails();
    setIsLoading(true);
    setHasError(false);
  }, [searchParams]);
    
  useEffect(() => {
    switchUAEVisaAndEDI()
  }, [showEID])

  // Determine the file URL based on the selected document type
  // let fileUrl = '';

  // Handler for document type selection
  const handleSelect = (type) => {
    setActiveType(type);
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set('documentType', type);
    setSearchParams(newSearchParams);
  };

  const handleFieldSave = (fieldKey, newValue) => {
    // Update the selectedParticipant state with the new value
    setSelectedParticipant((prevParticipant) => ({
      ...prevParticipant,
      [fieldKey]: newValue,
    }));
  };  

  const handleChangeCandidateSelect = (fullName) => {
    setActiveType('Passport');
    setSearchParams({ participant: fullName, documentType:'Passport'})
  };
  
  function getMimeType(extension) {
    const mimeTypes = {
      'jpeg': 'image/jpeg',
      'jpg': 'image/jpeg',
      'png': 'image/png',
      'webp': 'image/webp',
      'pdf': 'application/pdf',
    };
    const ext = extension.toLowerCase().trim();
    return mimeTypes[ext] || 'application/octet-stream';
  }

  function getMimeTypeFromFileName(fileName) {
    if (!fileName) return null;
    const extension = fileName.split('.').pop();
    return getMimeType(extension);
  }

  function getNestedValue(obj, path) {
    return path.split('.').reduce((acc, key) => acc && acc[key], obj);
  }  

  const filteredParticipants = allParticipants.filter(participant =>
    participant.fullName.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <>
      {/* Navbar */}
      <Navbar style={{ height: '5vh' }} bg="dark" variant="dark">
        <Container className="position-relative" style={{ height: '100%' }}>
          <Navbar.Brand href="/" style={{ height: '100%', padding: 0 }}>
            <img
              src="https://marcopolo-holidays.com/wp-content/uploads/2021/08/Marcopolo-Grey-transparent-logo-200-px.png"
              style={{ height: '100%', width: 'auto' }}
              alt="Logo"
            />
          </Navbar.Brand>
          <Navbar.Text 
            className="text-white position-absolute top-50 start-50 translate-middle"
            style={{cursor: 'pointer'}}
            onClick={() => (navigate(`/groups/${GroupTourId}`))}
          >
            {tourName}
          </Navbar.Text>
        </Container>
      </Navbar>

      {/* Main Content */}
      <Container fluid style={{ padding: 0, height: '95vh' }}>
        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
          {/* First Column - 20% */}
          <div style={{ width: '20%', backgroundColor: '#f8f9fa', padding: '10px', overflowY: 'auto' }}>
            <Form.Group className="mb-3 d-flex align-items-center">
              <Form.Control
                type="text"
                placeholder="Search participants"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="flex-grow-1" // This makes the input field grow to take available space
              />
              <Button
                variant="outline-primary"
                onClick={() => setFilter('')}
                className="ms-2 text-start custom-button" // Adds spacing and keeps text alignment
              >
                {allParticipants.length}
              </Button>
            </Form.Group>
            <ListGroup variant="flush">
              {filteredParticipants.length > 0 ? (
                filteredParticipants.map((participant) => (
                  <ListGroup.Item key={participant.id || participant?.fullName} className="p-2">
                    <Button
                      variant={searchParams.get('participant') === participant.fullName ? 'primary' : 'outline-primary'}
                      onClick={() => handleChangeCandidateSelect(participant.fullName)}
                      className="w-100 text-start d-flex align-items-center custom-button"
                      aria-pressed={searchParams.get('participant') === participant.fullName}
                      aria-label={`Select ${participant.fullName}`}
                      style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
                    >
                      <span style={{ textDecoration: 
                        participant?.nationality==='United Arab Emirates'?participant?.isVerifiedScore > 18?'line-through':'none':participant?.isVerifiedScore > 17?'line-through':'none' }}>
                        {participant.fullName}
                      </span>
                    </Button>
                  </ListGroup.Item>
                ))
              ) : (
                <ListGroup.Item className="p-2">
                  <p>No participants found.</p>
                </ListGroup.Item>
              )}
            </ListGroup>
          </div>

          {/* Second Column - 80% */}
          <div style={{ width: '80%', padding: '0px', margin: '0px' }}>

            {/* Document Type Selector */}
            <Container className="my-1 d-flex justify-content-center" style={{height: '4vh'}}>
                {documentTypes.map((type, index) => (
                    <Button
                      variant={activeType === type ? 'primary' : 'outline-primary'}
                      onClick={() => handleSelect(type)}
                      aria-pressed={activeType === type}
                      className="w-100 text-center d-flex align-items-center custom-button mx-3"
                      style={{ borderRadius: '5px' }}
                    >
                      {type}
                    </Button>
                ))}
            </Container>            
            {/* Participant Details */}
            <ParticipantDetails 
                selectedParticipant={selectedParticipant}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                handleFieldSave={handleFieldSave}
                tourId={GroupTourId}
                fetchBasicDetails={fetchBasicDetails}
                showEID={showEID}
                tourName={tourName}
                primaryDestination={primaryDestination}
            />

            {/* Embedded File */}
            <div style={{ width: '50%', height: '90vh', backgroundColor: '#dee2e6', padding: '10px', float: 'left', position: 'relative' }}>
              <DocumentPreview base64Data={base64Data} mimeType={fileMimeType}  />
            </div>
            {activeType === 'UAE Visa' && (
              <div className="" style={{position:'absolute', right: '20px', bottom:'20px'}}>
              <Button
                variant={'outline-primary'}
                onClick={() => {showEID?setShowEID(false):setShowEID(true)}}
                className="w-100 text-center d-flex align-items-center custom-button"
                style={{ borderRadius: '5px' }}
              >
                Show {showEID?'UAE Visa':'Emirates ID'}
              </Button>
            </div>
            )}
          </div>
        </div>
      </Container>
    </>
  )
}

export default GroupVerify;