export const toProperCase = (str) => {
    return str.toLowerCase().replace(/\b\w/g, function(char) {
        return char.toUpperCase();
    });    
};

export const isBirthDayInRange = (rangeStartDate, rangeEndDate, birthDate) => {
    const start = new Date(rangeStartDate);
    const end = new Date(rangeEndDate);
    const birth = new Date(birthDate);
    const birthMonth = birth.getMonth();
    const birthDay = birth.getDate();
    const startMonth = start.getMonth();
    const startDay = start.getDate();
    const endMonth = end.getMonth();
    const endDay = end.getDate();
    const currentYear = new Date().getFullYear();
    const birthThisYear = Date.UTC(currentYear, birthMonth, birthDay);
    const startThisYear = Date.UTC(currentYear, startMonth, startDay);
    const endThisYear = Date.UTC(currentYear, endMonth, endDay);
    return birthThisYear >= startThisYear && birthThisYear <= endThisYear;
};

export const calculateAge = (birthDate, otherDate) => {
    birthDate = new Date(birthDate);
    otherDate = new Date(otherDate);
  
    let age = otherDate.getFullYear() - birthDate.getFullYear();
    const m = otherDate.getMonth() - birthDate.getMonth();
    if (
      m < 0 ||
      (m === 0 && otherDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  }
  