import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  FormControl,
  Button,
  Checkbox,
  InputLabel,
  FormControlLabel,
  Grid,
  MenuItem,
  Select
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import API from "../../Visa/API";
import 'dayjs/locale/en-gb';
import { useNavigate } from "react-router-dom";


const ParticipantForm = ({ primaryDestination, fetchGroup, open, handleClose, participantData, handleSubmit, GroupTourId }) => {
  
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData({
      "schengenVisa": {
          "obtained": participantData?.obtained
      },
      "uaeResidenceVisa": {
          "stampedInPassport": participantData?.uaeResidenceVisa?.stampedInPassport,
          "emirateOfIssue": participantData?.uaeResidenceVisa?.emirateOfIssue,
          "dateOfExpiry": participantData?.uaeResidenceVisa?.dateOfExpiry,
          "uaeResidenceVisaURL": participantData?.uaeResidenceVisa?.uaeResidenceVisaURL,
          "profession": participantData?.uaeResidenceVisa?.profession
      },
      "preferences": {
          "privateRoomRequest": {
              "requested": participantData?.preferences?.privateRoomRequest?.requested,
              "additionalCost": participantData?.preferences?.privateRoomRequest?.additionalCost,
              "termsAccepted": participantData?.preferences?.privateRoomRequest?.termsAccepted,
          },
          "foodMedicalAllergies": participantData?.preferences?.foodMedicalAllergies,
          "ongoingHealthConditions": participantData?.preferences?.ongoingHealthConditions,
          "others": participantData?.preferences?.others
      },
      "typeformURLs": {
          "passportURL": participantData?.typeformURLs?.passportURL,
          "uaeResidenceVisaURL": participantData?.typeformURLs?.uaeResidenceVisaURL,
          "emiratesIdURL": participantData?.typeformURLs?.emiratesIdURL
      },
      "fullName": participantData?.fullName,
      "firstName": participantData?.firstName,
      "surName": participantData?.surName,
      "gender": participantData?.gender,
      "dateOfBirth": participantData?.dateOfBirth,
      "nationality": participantData?.nationality,
      "isUAEMentionedUAENat": participantData?.isUAEMentionedUAENat,
      "travelDocumentType": participantData?.travelDocumentType,
      "passportExpiryDate": participantData?.passportExpiryDate,
      "passportNumber": participantData?.passportNumber,
      "mobileNumber": participantData?.mobileNumber,
      "whatsappNumber": participantData?.whatsappNumber,
      "personalEmail": participantData?.personalEmail,
      "secondaryEmail": participantData?.secondaryEmail,
      "residentialAddress": participantData?.residentialAddress,
      "emirateOfResidence": participantData?.emirateOfResidence,
      "emergencyContacts": participantData?.emergencyContacts,
      "violations": {
          "isVisaRequired": participantData?.violations?.isVisaRequired,
          "isUAEVisaViolated": participantData?.violations?.isUAEVisaViolated,
          "isPassportViolated": participantData?.violations?.isPassportViolated,
          "isMinorDurVisaApp": participantData?.violations?.isMinorDurVisaApp
      },
      "isVerified": participantData?.isVerified,
      "isVerifiedScore": participantData?.isVerifiedScore,
      "visaApplicationDate": participantData?.visaApplicationDate
  })
  }, [participantData])

  // Handle input change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  function formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const month = monthNames[d.getMonth()];
    const year = String(d.getFullYear()).slice(-2); // Last two digits
    return `${day}-${month}-${year}`;
  }  

  // Form submission
  const onSubmit = async () => {
    const tour = await API.put('/groups/updateParticipant', {
      tourId: GroupTourId, fullName: participantData?.fullName, participantData: formData
    });
    handleClose();
    fetchGroup();
  };
  return (
    <>
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90vw',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          maxHeight: '90vh', 
          overflowY: 'auto', 
        }}
      >
        <Grid container>
          <Grid item md={8}>        
            <Typography variant="h6" component="h2" gutterBottom>
              Participant Details
            </Typography>
          </Grid>
          <Grid item md={4}>        
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={{ m: 2 }}
            >
              Close
            </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => {navigate(`/group-verification/${GroupTourId}?participant=${participantData.fullName}&documentType=Passport`)}}
                sx={{ m: 2 }}
              >
                Go To Verification
              </Button>            
          </Grid>
          {participantData?.violations?.isVisaRequired && (
            <>
          <Grid item md={12}>        
            <Typography variant="p" component="p" gutterBottom>
              <strong>{primaryDestination} Visa Application Details</strong>
            </Typography>
          </Grid>
          <Grid container sx={{ mb: 2 }}>
            <Grid item md={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker                                        
                      label='Visa Application Date'
                      value={participantData?.visaApplicationDate ? dayjs(participantData.visaApplicationDate) : null}
                      onChange={(date) => setFormData({ ...formData, visaApplicationDate: date.toISOString()})}
                      helperText='DD/MM/YYYY'
                      renderInput={(params) => (
                      <TextField                                             
                          fullWidth  // Ensure fullWidth is passed
                          variant='standard'  // Ensure variant is standard
                          sx={{ width: '100%' }}  // Ensure the component uses 100% width
                          placeholder='DD/MM/YYYY'
                          className='p-2'
                      />
                      )}
                  />
              </LocalizationProvider>
            </Grid>
          </Grid>
          </>
          )}
          <Grid item md={12}>        
            <Typography variant="p" component="p" gutterBottom>
              <strong>Passport Details</strong>
            </Typography>
          </Grid>          
          <Grid item md={4}>
            <TextField
              label={`Full Name ${participantData?.isVerified?.passportData?.fullName ? '✓' : '✗'}`}
              name="fullName"
              value={formData.fullName}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label={`First Name ${participantData?.isVerified?.passportData?.firstName ? '✓' : '✗'}`}              
              name="firstName"
              value={formData.firstName}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label={`Surname ${participantData?.isVerified?.passportData?.surName ? '✓' : '✗'}`}              
              name="surname"
              value={formData.surName}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label={`Gender ${participantData?.isVerified?.passportData?.gender ? '✓' : '✗'}`}              
              name="gender"
              value={formData.gender}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label={`Date Of Birth ${participantData?.isVerified?.passportData?.dateOfBirth ? '✓' : '✗'}`}              
              name="dateOfBirth"
              value={formatDate(formData.dateOfBirth)}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label={`Nationality ${participantData?.isVerified?.passportData?.nationality ? '✓' : '✗'}`}              
              name="nationality"
              value={formData.nationality}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label={`Travel Document Type ${participantData?.isVerified?.passportData?.travelDocumentType ? '✓' : '✗'}`}              
              name="travelDocumentType"
              value={formData?.travelDocumentType}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label={`Passport Expiry ${(participantData?.isVerified?.passportData?.nationality && !participantData?.violations?.isPassportViolated) ? '✓' : '✗'}`}              
              name="passportExpiryDate"
              value={formatDate(formData.passportExpiryDate)}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              label={`${participantData?.travelDocumentType} Number ${participantData?.isVerified?.passportData?.passportNumber ? '✓' : '✗'}`}              
              name="passportNumber"
              value={formData.passportNumber}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>          
          {formData.nationality==="United Arab Emirates" && (
            <Grid item md={12}>
              <TextField
                label={`Is UAE Mentioned in Passport ${participantData?.isVerified?.passportData?.isUAEMentionedUAENat ? '✓' : '✗'}`}              
                name="isUAEMentionedUAENat"
                value={formData.isUAEMentionedUAENat?formData.isUAEMentionedUAENat:''}
                fullWidth
                margin="normal"
                disabled
                variant='standard'
                className='p-2 noeditfield'
              />
            </Grid>            
          )}
          <Grid item md={12}>        
            <Typography variant="p" component="p" gutterBottom>
              <strong>UAE Visa Details</strong>
            </Typography>
          </Grid>
           <Grid item md={3}>
            <TextField
              label={`UAE Residence Visa Emirate Of Issue ${participantData?.isVerified?.uaeResidenceVisaData?.emirateOfIssue  ? '✓' : '✗'}`}              
              name="emirateOfIssue"
              value={formData?.uaeResidenceVisa?.emirateOfIssue}
              fullWidth
              margin="normal"
              variant='outlined'
              disabled
              className='p-2 noeditfield'
            />
          </Grid>
           <Grid item md={3}>
            <TextField
              label={`UAE Residence Visa Expiry ${participantData?.isVerified?.uaeResidenceVisaData?.dateOfExpiry && !participantData?.violations?.isUAEVisaViolated ? '✓' : '✗'}`}               
              name="dateOfExpiry"
              value={formatDate(formData?.uaeResidenceVisa?.dateOfExpiry)}
              fullWidth
              margin="normal"
              variant='outlined'
              disabled
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              label={`UAE Residence Visa linked to/stamped in the passport used to travel ${participantData?.isVerified?.uaeResidenceVisaData?.stampedInPassport ? '✓' : '✗'}`}                        
              name="stampedInPassport"
              value={formData?.uaeResidenceVisa?.stampedInPassport?'Yes':'No'}
              fullWidth
              margin="normal"
              variant='outlined'
              disabled
              className='p-2 noeditfield'
            />
          </Grid> 
          <Grid item md={3}>
            <TextField
              label={`Profession ${participantData?.isVerified?.uaeResidenceVisaData?.profession ? '✓' : '✗'}`}
              name="profession"
              value={formData?.uaeResidenceVisa?.profession}
              fullWidth
              margin="normal"
              variant='outlined'
              disabled
              className='p-2 noeditfield'
            />
          </Grid>          
          <Grid item md={12}>        
            <Typography variant="p" component="p" gutterBottom>
              <strong>Contact Details</strong>
            </Typography>
          </Grid>
          <Grid item md={6}>
            <TextField
              label={`Mobile Number ${participantData?.isVerified?.contactInformation?.mobileNumber ? '✓' : '✗'}`}              
              name="mobileNumber"
              value={formData.mobileNumber}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              label={`Whatsapp Number ${participantData?.isVerified?.contactInformation?.whatsappNumber ? '✓' : '✗'}`}              
              name="whatsappNumber"
              value={formData.whatsappNumber}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              label={`Personal Email ${participantData?.isVerified?.contactInformation?.personalEmail ? '✓' : '✗'}`}              
              name="personalEmail"
              value={formData.personalEmail}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              label={`Secondary Email ${participantData?.isVerified?.contactInformation?.secondaryEmail ? '✓' : '✗'}`}              
              name="secondaryEmail"
              value={formData.secondaryEmail}
              fullWidth
              margin="normal"
              disabled
              variant='outlined'
              className='p-2 noeditfield'
            />
          </Grid>
          <Grid item md={12}>        
            <Typography variant="p" component="p" gutterBottom>
              <strong>Emergency Contact Details</strong>
            </Typography>
          </Grid>
          <Grid item md={12}>
              {formData?.emergencyContacts?.map((contact, index) => (
                <Grid container key={contact._id} sx={{ mb: 2 }}>
                  <Grid item md={3}>
                    <TextField
                      label={`Emergency Contact ${index + 1} - Phone Number`}
                      name={`emergencyContactPhoneNumber${index}`}
                      value={contact?.phoneNumber}
                      onChange={(e) => setFormData({
                        ...formData,
                        emergencyContacts: formData.emergencyContacts.map((contactItem, i) =>
                          i === index ? { ...contactItem, phoneNumber: e.target.value } : contactItem
                        )
                      })}
                      fullWidth
                      margin="normal"
                      variant="standard"
                      className="p-2"
                    />
                  </Grid>
                  <Grid item md={3}>
                  <TextField
                    label={`Emergency Contact ${index + 1} - Email`}
                    name={`emergencyContactEmail${index}`}
                    value={contact?.email}
                    onChange={(e) => setFormData({
                      ...formData,
                      emergencyContacts: formData.emergencyContacts.map((contactItem, i) =>
                        i === index ? { ...contactItem, email: e.target.value } : contactItem
                      )
                    })}                    
                    fullWidth
                    margin="normal"
                    variant="standard"
                    className="p-2"
                  />
                  </Grid>
                  <Grid item md={3}>
                  <TextField
                    label={`Emergency Contact ${index + 1} - Name`}
                    name={`emergencyContactName${index}`}
                    value={contact?.name || 'N/A'} // Handle empty name field
                    onChange={(e) => setFormData({
                      ...formData,
                      emergencyContacts: formData.emergencyContacts.map((contactItem, i) =>
                        i === index ? { ...contactItem, name: e.target.value } : contactItem
                      )
                    })}   
                    fullWidth
                    margin="normal"
                    variant="standard"
                    className="p-2"
                  />
                  </Grid>
                  <Grid item md={3}>
                  <TextField
                    label={`Emergency Contact ${index + 1} - Relationship`}
                    name={`emergencyContactRelationship${index}`}
                    value={contact?.relationship}
                    onChange={(e) => setFormData({
                      ...formData,
                      emergencyContacts: formData.emergencyContacts.map((contactItem, i) =>
                        i === index ? { ...contactItem, relationship: e.target.value } : contactItem
                      )
                    })}                       
                    fullWidth
                    margin="normal"
                    variant="standard"
                    className="p-2"
                  />
                  </Grid>
                </Grid>
              ))}
          </Grid>          
          <Grid item md={12}>        
            <Typography variant="p" component="p" gutterBottom>
              <strong>Other Details</strong>
            </Typography>
          </Grid>
          <Grid item md={3}>
            <TextField
              label={`Emirate Of Residence`}              
              name="emirateOfResidence"
              value={formData?.emirateOfResidence}
              onChange={(e) => setFormData({
                ...formData,
                emirateOfResidence: e.target.value
              })}                     
              fullWidth
              margin="normal"
              variant='standard'
              className='p-2'
            />
          </Grid>
          <Grid item md={9}>
            <TextField
              label={`Residential Address`}              
              name="residentialAddress"
              value={formData.residentialAddress}
              onChange={(e) => setFormData({
                ...formData,
                residentialAddress: e.target.value
              })}               
              fullWidth
              margin="normal"
              variant='standard'
              className='p-2'
            />
          </Grid>
          <Grid item md={3}>
            <FormControl fullWidth margin="normal" variant="standard" className="p-2">
              <InputLabel>Private Room Request</InputLabel>
              <Select
                name="privateRoomRequest"
                value={formData?.preferences?.privateRoomRequest?.termsAccepted === true ? "true" : "false"}
                onChange={(e) => setFormData({
                  ...formData,
                  preferences: {
                    ...formData.preferences,
                    privateRoomRequest: {
                      ...formData.preferences.privateRoomRequest,
                      termsAccepted: e.target.value === "true", // Make sure the value is converted correctly
                      requested: e.target.value === "true"
                    }
                  }
                })}                     
                label="Private Room Request" // This works with InputLabel but is optional in this case
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3}>
            <TextField
              label={`Food Medical Allergies`}              
              name="foodMedicalAllergies"
              value={formData?.preferences?.foodMedicalAllergies}
              onChange={(e) => setFormData({
                ...formData,
                preferences: {
                  ...formData.preferences,
                  foodMedicalAllergies: e.target.value // Make sure the value is converted correctly
                }
              })}
              fullWidth
              margin="normal"
              variant='standard'
              className='p-2'
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              label={`Ongoing Health Conditions`}              
              name="ongoingHealthConditions"
              value={formData?.preferences?.ongoingHealthConditions}
              onChange={(e) => setFormData({
                ...formData,
                preferences: {
                  ...formData.preferences,
                  ongoingHealthConditions: e.target.value // Make sure the value is converted correctly
                }
              })}              
              fullWidth
              margin="normal"
              variant='standard'
              className='p-2'
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              label={`Others`}              
              name="Others"
              value={formData?.preferences?.others}
              onChange={(e) => setFormData({
                ...formData,
                preferences: {
                  ...formData.preferences,
                  others: e.target.value // Make sure the value is converted correctly
                }
              })}              
              fullWidth
              margin="normal"
              variant='standard'
              className='p-2'
            />
          </Grid>                     


        </Grid>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClose}
          sx={{ m: 2 }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          sx={{ m: 2 }}
        >
          Submit
        </Button>
      </Box>
    </Modal>
    </>
  );
};
export default ParticipantForm;